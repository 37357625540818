export type Coordinates = {
  lat: number | string
  lon: number | string
}

declare global {
  interface Window {
    geolocation_coordinates: boolean | Coordinates
    geolocation_permission: 'granted' | 'denied' | 'prompt' | boolean
    htmx: any // meh
  }
}

export const geolocation_options = {
  enableHighAccuracy: false,
  timeout: 100,
  maximumAge: 5 * 60 * 1000
}
window.geolocation_coordinates = false
window.geolocation_permission = 'prompt'

export function isCoordinates(crd: Coordinates | boolean): crd is Coordinates {
  if (!crd) {
    return false
  }
  return (
    (crd as Coordinates).lat !== undefined &&
    (crd as Coordinates).lon !== undefined
  )
}

/**
 * Checks if the user has granted permission for geolocation.
 * @returns A promise that resolves to a boolean value indicating whether the permission is granted or not.
 */
export function checkPermission(): Promise<
  'granted' | 'denied' | 'prompt' | false
> {
  if (!navigator.permissions) {
    return Promise.resolve(false)
  }
  return new Promise((resolve, reject) => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        resolve(result.state)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export function getPosition(
  options: PositionOptions = geolocation_options
): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )
}

export async function getCoordinates() {
  const coordinates = await getPosition()
    .then((pos) => {
      return {
        lat: pos.coords.latitude,
        lon: pos.coords.longitude
      }
    })
    .catch((err) => {
      console.error(err)
      return false
    })
  return coordinates
}

export default async function init() {
  const events_location_triggers = document.querySelectorAll(
    '[data-events-location-trigger]'
  )

  const similar_courses_triggers = document.querySelectorAll(
    '[data-similar-courses-location-trigger]'
  )

  window.geolocation_permission = await checkPermission()

  if (!navigator.geolocation) {
    events_location_triggers.forEach((btn) => btn.remove())
    similar_courses_triggers.forEach((btn) => btn.remove())
    return
  }

  if (events_location_triggers && events_location_triggers.length) {
    import('./get-events-by-location')
      .then((module) => {
        module.default('[data-events-location-trigger]')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (similar_courses_triggers && similar_courses_triggers.length) {
    import('./get-similar-courses-by-location')
      .then((module) => {
        module.default('[data-similar-courses-location-trigger]')
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


