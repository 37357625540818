import {
  drawer_closed_event,
  drawer_closing_event,
  drawer_opened_event,
  drawer_opening_event
} from '../custom-events'

type UI_STATE = {
  open: boolean
  transitioning: boolean
  type: string
  current_target: HTMLElement | null
  elements: Array<HTMLElement | null>
}
const ui_state: UI_STATE = {
  open: false,
  transitioning: false,
  type: '',
  current_target: null,
  elements: []
}

let open_button: HTMLElement | null
let drawer_holder: HTMLElement | null
let menu_button: HTMLElement | null
let search_toggle_button: HTMLElement | null
let search_filter_toggle_button: HTMLElement | null
let region_select_toggle_buttons: NodeListOf<HTMLElement> | []
let close_buttons: NodeListOf<HTMLElement> | []

// Create a background element to close the drawer
const drawer_backdrop_element = document.createElement('div')
drawer_backdrop_element.classList.add('DrawerBackdrop')

// Close the drawer when pressing the escape key
function handleCloseOnEsc(e: KeyboardEvent) {
  const target = e.target as HTMLElement
  if (e.key === 'Escape' && target.nodeName !== 'INPUT' && ui_state.open) {
    return toggleDrawer()
  }
}
// Close the drawer when clicking on the background, if its open
function handleCloseOnDrawerBackdrop() {
  if (ui_state.open) {
    toggleDrawer()
  }
}
// handler for when the drawer is transitioning
function drawerTransitionStartedEventHandler(e: TransitionEvent) {
  if (e.target !== ui_state.current_target && e.propertyName !== 'clip-path') {
    return false
  }
  // Set the transitioning state to true and add the attribute to the drawer
  ui_state.transitioning = true
  ui_state.current_target?.setAttribute(
    'data-is-transitioning',
    ui_state.transitioning.toString()
  )
  // Disable the menu button while transitioning
  menu_button?.setAttribute('disabled', '')

  // Add the background element to the DOM if we are opening the drawer,
  // otherwise start transitioning it out
  if (ui_state.open) {
    drawer_holder?.appendChild(drawer_backdrop_element)
    setTimeout(function () {
      drawer_backdrop_element.classList.add('is-visible')
    }, 100)
  } else {
    drawer_backdrop_element.classList.remove('is-visible')
  }
}

// Hndler for when the drawer is done transitioning
function drawerTransitionEndedEventHandler(e: TransitionEvent) {
  if (e.target !== ui_state.current_target && e.propertyName !== 'clip-path') {
    return false
  }
  // Set the transitioning state to false and add the attribute to the drawer
  ui_state.transitioning = false
  ui_state.current_target?.setAttribute(
    'data-is-transitioning',
    ui_state.transitioning.toString()
  )
  // Enable the menu button again
  menu_button?.removeAttribute('disabled')
  // set the aria-hidden attribute for the current target
  ui_state.current_target?.setAttribute(
    'aria-hidden',
    (!ui_state.open).toString()
  )

  // add atribute to body
  document.body.setAttribute('data-menu-visible', ui_state.open.toString())
  // Remove the background element from the DOM if we are closing the drawer,
  // otherwise add the event listener to close the drawer when clicking on the background
  if (!ui_state.open) {
    drawer_backdrop_element.removeEventListener(
      'click',
      handleCloseOnDrawerBackdrop
    )
    // Dispatch custom event
    document.removeEventListener('keydown', handleCloseOnEsc)
    drawer_backdrop_element.remove()
    window.dispatchEvent(drawer_closed_event)
  } else {
    // Dispatch custom event
    document.addEventListener('keydown', handleCloseOnEsc)
    drawer_backdrop_element.addEventListener(
      'click',
      handleCloseOnDrawerBackdrop
    )
    window.dispatchEvent(drawer_opened_event)
  }
}
// register the event handlers for the drawer
function registerTransitionEventHandlers() {
  // Eventlisterns for the transsitioning
  ui_state.current_target?.addEventListener(
    'transitionstart',
    drawerTransitionStartedEventHandler
  )
  ui_state.current_target?.addEventListener(
    'transitionend',
    drawerTransitionEndedEventHandler
  )
}
function updateMenuButtonLabel() {
  if (!menu_button) {
    return false
  }

  // Update the text in the menu_button
  if (menu_button) {
    const btn_label_text = ui_state.open
      ? menu_button.getAttribute('data-btn-label-open')
      : menu_button.getAttribute('data-btn-label-closed')
    const aria_label_text = ui_state.open
      ? menu_button.getAttribute('data-label-closed')
      : menu_button.getAttribute('data-label-open')

    // Set aria-attributes
    menu_button.setAttribute('aria-expanded', ui_state.open.toString())
    menu_button.setAttribute('aria-pressed', ui_state.open.toString())
    menu_button.classList.toggle('ui-state-active', ui_state.open)
    menu_button.setAttribute(
      'aria-label',
      aria_label_text ? aria_label_text : ''
    )
    if (ui_state.open) {
      menu_button.setAttribute('tabindex', '0')
    }
    menu_button?.setAttribute(
      'aria-controls',
      ui_state.current_target?.id ?? ''
    )

    menu_button?.setAttribute('data-drawer-type', ui_state.type ?? '')

    const btn_label: HTMLElement | null =
      menu_button.querySelector('.MenuButtonLabel') ?? null
    if (btn_label) {
      btn_label.innerText = btn_label_text ? btn_label_text : ''
    }
  }
}

function openDrawer() {
  ui_state.open = true

  // Dispatch custom event
  window.dispatchEvent(drawer_opening_event)

  ui_state.current_target?.setAttribute('aria-hidden', 'false')
  ui_state.type =
    ui_state.current_target?.getAttribute('data-drawer-type') ?? ''

  // Allow the elements display:none to be removed before transitioning
  return setTimeout(function () {
    ui_state.current_target?.setAttribute(
      'data-ui-visible',
      ui_state.open.toString()
    )
  }, 100)
}

function closeDrawer() {
  ui_state.open = false
  // Dispatch custom event
  window.dispatchEvent(drawer_closing_event)
  ui_state.type = ''
  // the default value for the aria-controls attribute
  const default_val = menu_button?.getAttribute('data-control-for') ?? ''
  menu_button?.setAttribute('aria-controls', default_val)
  // Set the aria-hidden attribute to false to hide the drawer
  ui_state.current_target?.setAttribute(
    'data-ui-visible',
    ui_state.open.toString()
  )
  return
}

// main fn to toggle main nav or search
function toggleDrawer() {
  // if its open, close it, otherwise open it
  if (ui_state.open) {
    closeDrawer()
  } else {
    const drawer_target_id = open_button?.getAttribute('data-control-for')

    ui_state.current_target = ui_state.elements.find(
      (element) => element?.id === drawer_target_id
    ) as HTMLElement

    openDrawer()
  }

  // register the event handlers for the drawer
  registerTransitionEventHandlers()
  // update the menu button label accoding to the state
  updateMenuButtonLabel()
}

function MainNavigation() {
  // the drawer holder
  drawer_holder = document.querySelector('#SiteNavigation')
  // Bail if we dont have the drawer holder elem
  if (!drawer_holder) {
    return
  }

  close_buttons = document.querySelectorAll('.DrawerCloseButton') ?? []

  // the menu button in the header. acts as close btn for the search as well
  menu_button = drawer_holder.querySelector('#MainNavigationToggleButton')

  // the search button in the header
  search_toggle_button = drawer_holder.querySelector('#SearchToggleButton')

  // the search filter btn
  search_filter_toggle_button = document.querySelector(
    '#SearchFilterToggleButton'
  )
  // the region select btns
  region_select_toggle_buttons =
    document.querySelectorAll('.RegionSelectTrigger') ?? []

  // Check if we have our elements to open/close
  ui_state.elements =
    Array.from(document.querySelectorAll('[data-drawer-type]')).map(
      (element) => element as HTMLElement
    ) ?? []
  if (!menu_button || ui_state.elements.length === 0) {
    return
  }

  // Add event listeners to the buttons to open/close the drawer
  const triggers = [
    menu_button,
    search_toggle_button,
    search_filter_toggle_button,
    ...region_select_toggle_buttons
  ]
  triggers.forEach((button) => {
    button?.addEventListener('click', (e) => {
      // the big button that opened the drawer, we need a data attribute from it
      open_button = e.currentTarget as HTMLElement
      toggleDrawer()
    })
  })

  close_buttons.forEach((button) => {
    button?.addEventListener('click', (e) => {
      toggleDrawer()
    })
  })

  // only load the main-menu module on mouseenter for the menu btn
  menu_button.addEventListener(
    'mouseenter',
    (e) =>
      import('../main-menu').then((module) => {
        module.default()
      }),
    {
      once: true
    }
  )
}

export function openRegionSelectDrawer() {
  // Meh, but works
  const trigger = document.querySelector(
    '.AdditionalNavColumn > .RegionSelectTrigger'
  ) as HTMLElement
  if (trigger) {
    open_button = trigger as HTMLElement
    toggleDrawer()
  }
}

export default MainNavigation
