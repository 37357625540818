function Accordion() {
  document.querySelectorAll('[data-ui-accordion]').forEach((accordion) => {
    const target = accordion.getAttribute('data-ui-target')

    accordion.addEventListener('click', (e) => {
      const targetElement = document.querySelector(target)

      if (targetElement) {
        const visible =
          targetElement.dataset.uiVisible === 'true' ? false : true
        targetElement.dataset.uiVisible = visible.toString()

        if (visible) {
          accordion.setAttribute('data-ui-open', '')
        } else {
          accordion.removeAttribute('data-ui-open')
        }
      }
    })
  })
}

export default Accordion
