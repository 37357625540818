import { getCoordinates } from "./geolocation";

function updateGroup(selector, checked)
{
  document.querySelectorAll(selector).forEach((el) => {
    el.checked = checked;
    updateLabel(el);
  });
}

function updateLabel(checkbox)
{
  let label = checkbox.getAttribute('id');
  label = label ? document.querySelector(`label[for=${label}]`) : null;
  if (label)
  {
    if (checkbox.checked) {
      label.classList.add('font-semibold');
    } else {
      label.classList.remove('font-semibold');
    }
  }

}

function Setup() {
  document.addEventListener('click', function (event) {
    const element = event.target?.closest('.ResetFormButton')
    if (element) {
      const selector = element?.dataset.selector ?? ''
      document.querySelectorAll(selector)?.forEach((checkbox) => {
        checkbox.checked = false
      })
    }
  })

  document.addEventListener('change', function (event) {
    const element = event.target?.closest('#search-filters [type=checkbox]');
    if (element) {
      updateLabel(element);
    }
  });

  document.addEventListener('change', function(event) {
    const element = event.target?.closest('#search-filters [data-parent]');

    if (element) {
      let parent = element.dataset.parent;
      parent = document.getElementById(parent);

      if (parent) {
        parent.dispatchEvent(new Event('check_group', {bubbles: true}));
      }
    }
  });

  document.addEventListener('check_group', function(event) {
    const element = event.target;
    const group = event.target?.getAttribute('id');

    if (group && element)
    {
      let checked = Array.from(document.querySelectorAll(`[data-parent=${group}]`)).every((el) => { return el.checked; });
      if (element.checked !== checked)
      {
        element.checked = checked;
      }
      updateLabel(element);

    }

  });


  document.addEventListener('change', function (event) {
    const element = event.target;

    if (element && element.dataset.grouped)
    {
      const parent = element.getAttribute('id');
      updateGroup(`[data-parent=${parent}]`, element.checked);
    }
  });
  if (htmx)
  {
    htmx.onLoad(function (){
      document.querySelectorAll('#search-filters [type=checkbox]').forEach((checkbox) => {
        updateLabel(checkbox);
      });

      document.querySelectorAll('#search-filters [data-grouped]').forEach((checkbox) => {
        checkbox.dispatchEvent(new Event('check_group', {bubbles: true}));
      });
    });


  }
  document.addEventListener('change', function (event) {
    const element = event.target?.closest('[name=sort]');

    if (element) {
      function submitSearchForm() {
        if (htmx)
        {

          htmx.trigger('#CourseSearch', 'submit');
        }
        else
        {
          document.querySelector('#CourseSearch').submit();
        }
      }

      if (element.value === 'geo')
      {
        getCoordinates().then(function (coords) {

          const lat = document.querySelector('[name=lat]');
          const lon = document.querySelector('[name=lon]');
          if (lat && lon)
          {
            lat.removeAttribute('disabled');
            lon.removeAttribute('disabled');
            lat.value = coords.lat;
            lon.value = coords.lon;
            setTimeout(submitSearchForm, 50);
          }
        });
      }
      else
      {
        submitSearchForm();
      }
    }

  })
}

export default Setup
