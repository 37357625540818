import { openRegionSelectDrawer } from './site-navigation'

const show_region_select_cookie = 'region_select_first_visit'

function setCookie(cname: string, cvalue: string | number, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function RegionSelect() {
  const selected_region_cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(show_region_select_cookie))

  if (!selected_region_cookie) {
    openRegionSelectDrawer()
    setCookie(show_region_select_cookie, '', 600)
  }
}

export default RegionSelect
