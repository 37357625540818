export const custom_drawer_event_name = 'DRAWER_STATE_CHANGE'

export const drawer_opening_event = new CustomEvent(custom_drawer_event_name, {
  detail: 'opening',
  bubbles: false
})
export const drawer_opened_event = new CustomEvent(custom_drawer_event_name, {
  detail: 'opened',
  bubbles: false
})
export const drawer_closed_event = new CustomEvent(custom_drawer_event_name, {
  detail: 'closed',
  bubbles: false
})
export const drawer_closing_event = new CustomEvent(custom_drawer_event_name, {
  detail: 'closing',
  bubbles: false
})
export const external_close_drawer = new CustomEvent(custom_drawer_event_name, {
  detail: 'close_from_external_src',
  bubbles: false
})

export type drawer_event_type = CustomEvent
